import classNames from "classnames";
import gql from "graphql-tag";
import sum from "lodash/sum";
import { useEffect, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { useMemoOne } from "use-memo-one";

import { ImpactUnit } from "../../__generated__/globalTypes";
import {
  EffectTypeAbsoluteValue,
  useImpactCategoryEffectTypePerPortion,
} from "../../domain/EffectType";
import { FunctionalUnit } from "../../domain/functionalUnits";
import {
  ImpactCategory,
  useImpactCategoryLabel,
} from "../../domain/impactCategories";
import useAvailableImpactCategories, {
  useDetailedLifecycleAnalysis,
  useFoodManufacturerOrganization,
  useAdditionalImpactCategories,
} from "../../services/useOrganizationFeatures";
import { useTracking } from "../../tracking";
import UserVisibleError from "../../util/UserVisibleError";
import { mintPastel, qualitativeDataColors } from "../graphs/colors";
import ErrorAlert from "../utils/ErrorAlert";
import HelpModalTooltip from "../utils/HelpModalTooltip";
import InlineTitleToggle from "../utils/InlineTitleToggle";
import { Panel } from "../utils/Panel";
import Sunburst, {
  ROOT_NODE_NAME,
  TRANSITION_DURATION_MILLISECONDS,
} from "../utils/Sunburst";
import {
  Cooking,
  EndMile,
  Farm,
  Packaging,
  Processing,
  Retail,
  Transport,
  Waste,
} from "../utils/Vectors/illustrations";
import FunctionalUnitDropdown from "./FunctionalUnitDropdown";
import {
  LifeCycleImpacts_Effects,
  LifeCycleImpacts_RecipeImpact as RecipeImpact,
} from "./LifeCycleImpacts.graphql";
import LifeCycleImpactTable from "./LifeCycleImpactTable";
import "./LifeCycleImpacts.css";
import { recipePageTracking } from "./RecipePage";
import { useRecipeImpactIsUnavailable } from "./useRecipeImpactIsUnavailable";

const chartName = "Life Cycle Analysis"; // For tracking.

const STAGES_WITH_PRODUCT_AND_INGREDIENT_SUB_IMPACTS = [
  "processing",
  "packaging",
  "transport",
];

const STAGES_WITH_ONLY_INGREDIENT_SUB_IMPACTS = ["farm"];

const STAGES_WITH_INDIVIDUAL_INGREDIENT_SUB_IMPACTS = [
  "processing",
  "packaging",
];

interface LifeCycleImpactsProps {
  impact: RecipeImpact;
  nullProductWeightErrorMessage: string;
  productWeightKgPerServing: number | null;
}

export default function LifeCycleImpacts(props: LifeCycleImpactsProps) {
  const { impact, productWeightKgPerServing, nullProductWeightErrorMessage } =
    props;

  const additionalImpactCategories = useAdditionalImpactCategories();
  const availableImpactCategories = useAvailableImpactCategories();
  const foodManufacturerOrganization = useFoodManufacturerOrganization();
  const impactCategoryEffectTypePerPortion =
    useImpactCategoryEffectTypePerPortion;
  const impactCategoryLabel = useImpactCategoryLabel;
  const recipeImpactIsUnavailable = useRecipeImpactIsUnavailable;
  const { trackFunctionalUnitSet, trackImpactCategorySet } = useTracking();

  const [hoverStage, setHoverStage] = useState<LifeCycleStage | null>(null);
  const [transitioning, setTransitioning] = useState<boolean>(true);
  const [loadingImpactCategory, setLoadingImpactCategory] =
    useState<boolean>(true);
  const [impactCategory, setImpactCategory] = useState<ImpactCategory>(
    ImpactCategory.GHG
  );
  const root: LifeCycleStage = useLifeCycleStages(impact, impactCategory);
  const [activeStage, setActiveStage] = useState<LifeCycleStage>(root);
  const [functionalUnit, setFunctionalUnit] = useState<FunctionalUnit>(
    FunctionalUnit.KG
  );

  useEffect(() => {
    setTransitioning(true);
    setTimeout(() => {
      setTransitioning(false);
    }, TRANSITION_DURATION_MILLISECONDS);
  }, [activeStage]);

  useEffect(() => {
    setLoadingImpactCategory(true);
    setActiveStage(root);
    setTimeout(() => {
      // Using setTimeout here to get the chart to re-render.
      setLoadingImpactCategory(false);
    }, 0);
  }, [impactCategory, root]);

  const goBack = () => {
    if (activeStage.parent !== null) {
      setActiveStage(activeStage.parent);
    }
  };

  const handleActiveStageChange = (stage: LifeCycleStage) => {
    if (stage && stage.depth > activeStage.depth && stage.children.length > 0) {
      setActiveStage(stage);
      setHoverStage(null);
    }
  };

  const handleHover = (stage: LifeCycleStage | null) => {
    // Clear hover stage so fade-in works when hovering between slices.
    setHoverStage(null);
    if (!transitioning && activeStage !== stage) {
      setHoverStage(stage);
    }
  };

  const handleFunctionalUnitChange = (functionalUnit: FunctionalUnit) => {
    setFunctionalUnit(functionalUnit);
    trackFunctionalUnitSet({
      chart: chartName,
      functionalUnit,
      foodManufacturerOrganization,
    });
  };

  const handleImpactCategoryChange = (impactCategory: ImpactCategory) => {
    setImpactCategory(impactCategory);
    trackImpactCategorySet({
      chart: chartName,
      pageName: recipePageTracking.pageName,
      impactCategory,
    });
  };

  const impactCategoryDisabled = (impactCategory: ImpactCategory): boolean => {
    const impactMagnitude =
      impactCategoryEffectTypePerPortion(impactCategory).get(impact);
    return (
      recipeImpactIsUnavailable(impact, impactCategory) ||
      impactMagnitude === 0 ||
      impactMagnitude === null
    );
  };

  return (
    <Panel
      className={classNames("LifeCycleImpacts_Panel", {
        LifeCycleImpacts_Transitioning: transitioning,
      })}
    >
      <div className="LifeCycleImpacts_Header">
        <h3 className="d-flex flex-row">
          <FormattedMessage
            id="components/recipes/LifeCycleImpacts:title"
            defaultMessage="Life Cycle Analysis"
          />
          {additionalImpactCategories && (
            <>
              {" "}
              <FormattedMessage
                id="components/recipes/LifeCycleImpacts:of"
                defaultMessage="of"
              />
              <div className="ml-1">
                <InlineTitleToggle<ImpactCategory>
                  optionIsDisabled={impactCategoryDisabled}
                  onChange={handleImpactCategoryChange}
                  options={availableImpactCategories}
                  selectedOption={impactCategory}
                  renderOption={impactCategoryLabel}
                />
              </div>
            </>
          )}
          <LifeCycleImpactsTooltip />
        </h3>
        <FunctionalUnitDropdown
          selectedFunctionalUnit={functionalUnit}
          onChange={handleFunctionalUnitChange}
        />
      </div>
      <div className="LifeCycleImpacts_SunburstAndTableContainer">
        <div className="w-50">
          {!loadingImpactCategory && (
            <Sunburst
              activeStage={activeStage}
              goBack={goBack}
              hoverStage={hoverStage}
              onActiveStageChange={handleActiveStageChange}
              onHover={handleHover}
              root={root}
              transitioning={transitioning}
            />
          )}
        </div>
        <div className="w-50">
          {productWeightKgPerServing ? (
            <LifeCycleImpactTable
              functionalUnit={functionalUnit}
              activeStage={activeStage}
              goBack={goBack}
              impactCategory={impactCategory}
              onActiveStageChange={handleActiveStageChange}
              productWeightKgPerServing={productWeightKgPerServing}
              root={root}
            />
          ) : (
            <ErrorAlert
              error={new UserVisibleError(nullProductWeightErrorMessage)}
            />
          )}
        </div>
      </div>
    </Panel>
  );
}

function LifeCycleImpactsTooltip() {
  return (
    <HelpModalTooltip
      title={
        <FormattedMessage
          defaultMessage="The Life Cycle Impact Analysis Tool"
          id="components/recipes/LifeCycleImpacts:helpModalTitle"
        />
      }
    >
      <FormattedMessage
        defaultMessage="
                    <p1>The Life Cycle Impact Analysis tool is a hierarchical graph that allows you to delve into the impact of each life cycle stage of your product. The life cycle stages are represented by the centre of the circle, and some stages broken down further in the outer rings. Click on the life cycle stages to analyse them in more detail and use the back arrow to return to the previous state. You can also use the table to view the same impact breakdowns.</p1>
                    <p2>Note that some life cycle stages include ingredient impacts as well those of your own product. For example, the packaging life cycle stage consists of the impact of ingredient packaging for transport between processing sites, to warehouses/distribution centres, to retail/wholesale outlets, and to your facility, as well as the packaging impact of your own products.</p2>
                "
        id="components/recipes/LifeCycleImpacts:helpModalBody"
        values={{
          p1: (chunks: React.ReactNode) => <p>{chunks}</p>,
          p2: (chunks: React.ReactNode) => <p className="mb-0">{chunks}</p>,
        }}
      />
    </HelpModalTooltip>
  );
}

export class LifeCycleStage {
  parent: LifeCycleStage | null;
  children: LifeCycleStage[] = [];
  Icon?: (props: { width: number }) => JSX.Element;
  id: string;
  impactMagnitude: number;
  name: string;
  percentage: number;
  depth: number;
  baseColor: string | null;

  static getDepth(stage: LifeCycleStage): number {
    if (stage.parent === null) {
      return 0;
    } else {
      return 1 + this.getDepth(stage.parent);
    }
  }

  static getId(name: string, parent: LifeCycleStage | null): string {
    if (parent === null) {
      return name;
    } else {
      return `${this.getId(parent.name, parent.parent)} - ${name}`;
    }
  }

  static getPercentage(
    impactMagnitudePerKg: number,
    parent: LifeCycleStage | null
  ) {
    if (parent === null) {
      return 100;
    } else if (parent.impactMagnitude === 0) {
      return 0;
    } else {
      return (impactMagnitudePerKg / parent.impactMagnitude) * 100;
    }
  }

  static getHeight(stage: LifeCycleStage): number {
    if (stage.children.length === 0) {
      return 0;
    } else {
      return (
        1 +
        Math.max(
          ...stage.children.map((child) => LifeCycleStage.getHeight(child))
        )
      );
    }
  }

  constructor(
    name: string,
    parent: LifeCycleStage | null,
    impactMagnitude: number,
    Icon?: (props: { width: number }) => JSX.Element,
    color?: string
  ) {
    this.name = name;
    this.parent = parent;
    this.id = LifeCycleStage.getId(name, parent);
    this.Icon = Icon;
    this.impactMagnitude = impactMagnitude;
    this.percentage = LifeCycleStage.getPercentage(impactMagnitude, parent);
    this.depth = LifeCycleStage.getDepth(this);
    if (this.depth === 1 && color === undefined) {
      throw new Error("Must provide a color for top-level life cycle stages");
    }
    this.baseColor = color ?? parent?.baseColor ?? null;
    if (this.parent) this.parent.children.push(this);
  }
}

const getStageEffect = (
  codename: string,
  effectType: EffectTypeAbsoluteValue,
  impactCategory: ImpactCategory,
  isFoodManufacturerOrganization: boolean,
  stageImpactsByCodeName: Map<
    string,
    {
      codename: string;
      impactPerKg: number | null;
      impactPerRecipe: number | null;
      impactPerRootRecipeServing: number | null;
      impactUnit: ImpactUnit;
    }
  >
) => {
  let impact = stageImpactsByCodeName.get(codename) ?? null;
  if (codename === "ghg_processing" && isFoodManufacturerOrganization) {
    const cooking_impact = stageImpactsByCodeName.get("ghg_cooking") ?? null;
    if (impact === null) {
      impact = cooking_impact;
    } else if (cooking_impact !== null) {
      if (impact.impactPerRecipe !== null) {
        impact.impactPerRecipe += cooking_impact.impactPerRecipe ?? 0;
      }
      if (impact.impactPerRootRecipeServing !== null) {
        impact.impactPerRootRecipeServing +=
          cooking_impact.impactPerRootRecipeServing ?? 0;
      }
    }
  }
  const impactUnit = impact?.impactUnit;
  let effects: any;
  if (impactUnit === ImpactUnit.CO2E) {
    effects = {
      ghgPerKg: impact?.impactPerKg ?? null,
      ghgPerRecipe: impact?.impactPerRecipe ?? null,
      ghgPerRootRecipeServing: impact?.impactPerRootRecipeServing ?? null,
    };
  } else if (impactUnit === ImpactUnit.M2_YEAR_LAND_USE) {
    effects = {
      landUsePerKg: impact?.impactPerKg ?? null,
      landUsePerRecipe: impact?.impactPerRecipe ?? null,
      landUsePerRootRecipeServing: impact?.impactPerRootRecipeServing ?? null,
    };
  } else if (impactUnit === ImpactUnit.LITRES_WATER_USE) {
    effects = {
      waterUsePerKg: impact?.impactPerKg ?? null,
      waterUsePerRecipe: impact?.impactPerRecipe ?? null,
      waterUsePerRootRecipeServing: impact?.impactPerRootRecipeServing ?? null,
    };
  } else {
    effects = null;
  }

  return effectType.get(
    impact
      ? {
          effects,
        }
      : null
  );
};

function addIngredientLifeCycleStages(
  ingredientsLabelledValues: Array<LabelledValue>[],
  id: string,
  parent: LifeCycleStage
) {
  for (const ingredientLabelledValues of ingredientsLabelledValues) {
    const ingredientLabelledValuesById = Object.fromEntries(
      ingredientLabelledValues.map((value) => [value.id, value])
    );
    const ingredientLabelledValue = ingredientLabelledValuesById[id];
    new LifeCycleStage(
      ingredientLabelledValue?.label,
      parent,
      ingredientLabelledValue.value
    );
  }
}

function useLifeCycleStages(
  impact: RecipeImpact,
  impactCategory: ImpactCategory
): LifeCycleStage {
  const detailedLifecycleAnalysis = useDetailedLifecycleAnalysis();
  const effectType = useImpactCategoryEffectTypePerPortion(impactCategory);
  const intl = useIntl();
  const isFoodManufacturerOrganization = useFoodManufacturerOrganization();

  return useMemoOne(() => {
    const productLabel = intl.formatMessage({
      defaultMessage: "Product",
      id: "components/recipes/LifeCycleImpacts:productLabel",
    });

    const ingredientLabel = intl.formatMessage({
      defaultMessage: "Ingredient",
      id: "components/recipes/LifeCycleImpacts:ingredientLabel",
    });

    const lifeCycleStageLabelledValues = effectsToLabelledValues(
      impact.effects,
      effectType,
      impactCategory,
      intl,
      isFoodManufacturerOrganization
    );

    const lifeCycleStageLabelledValuesById = new Map(
      lifeCycleStageLabelledValues.map((value) => [value.id, value])
    );

    const ingredientsLabelledValues = impact.ingredients.map((ingredient) =>
      effectsToLabelledValues(
        ingredient.effects,
        effectType,
        impactCategory,
        intl,
        isFoodManufacturerOrganization,
        ingredient.name
      )
    );

    const totalGhg = sum(lifeCycleStageLabelledValues.map((_) => _.value));

    const root = new LifeCycleStage(ROOT_NODE_NAME, null, totalGhg);

    let i = 0;
    for (const [id, value] of lifeCycleStageLabelledValuesById) {
      const topLevelLifeCycleStage = new LifeCycleStage(
        value.label,
        root,
        value.value,
        value.Icon,
        lifeCycleDataColors[i % lifeCycleDataColors.length]
      );
      i++;

      if (detailedLifecycleAnalysis) {
        const ingredientsImpact = sum(
          ingredientsLabelledValues
            .flatMap((ingredientLabelledValues) =>
              ingredientLabelledValues.filter(
                (ingredientLabelledValue) => ingredientLabelledValue.id === id
              )
            )
            .map((ingredientLabelledValue) => ingredientLabelledValue.value)
        );

        if (STAGES_WITH_PRODUCT_AND_INGREDIENT_SUB_IMPACTS.includes(id)) {
          const productImpact = value.value - ingredientsImpact;
          new LifeCycleStage(
            `${productLabel} ${value.label}`,
            topLevelLifeCycleStage,
            productImpact
          );
          const ingredientsLifeCycleStage = new LifeCycleStage(
            `${ingredientLabel} ${value.label}`,
            topLevelLifeCycleStage,
            ingredientsImpact
          );
          if (STAGES_WITH_INDIVIDUAL_INGREDIENT_SUB_IMPACTS.includes(id)) {
            addIngredientLifeCycleStages(
              ingredientsLabelledValues,
              id,
              ingredientsLifeCycleStage
            );
          }
        }

        if (STAGES_WITH_ONLY_INGREDIENT_SUB_IMPACTS.includes(id)) {
          addIngredientLifeCycleStages(
            ingredientsLabelledValues,
            id,
            topLevelLifeCycleStage
          );
        }
      }
    }
    return root;
  }, [
    detailedLifecycleAnalysis,
    effectType,
    impact,
    impactCategory,
    intl,
    isFoodManufacturerOrganization,
  ]);
}

interface LabelledValue {
  id: string;
  label: string;
  value: number;
  Icon?: (props: { width: number }) => JSX.Element;
}

const effectsToLabelledValues = (
  effects: LifeCycleImpacts_Effects | null,
  effectType: EffectTypeAbsoluteValue,
  impactCategory: ImpactCategory,
  intl: IntlShape,
  isFoodManufacturerOrganization: boolean,
  label?: string
): Array<LabelledValue> => {
  const stageImpacts = effects?.stages ?? [];

  const stageImpactsByCodeName = new Map(
    stageImpacts.map((stageImpact) => [stageImpact.codename, stageImpact])
  );

  const lifeCycleStages = getLifeCycleStages(
    intl,
    isFoodManufacturerOrganization
  );

  return lifeCycleStages.map((stage) => {
    const value = sum(
      stage.codenames.map(
        (codename) =>
          getStageEffect(
            codename,
            effectType,
            impactCategory,
            isFoodManufacturerOrganization,
            stageImpactsByCodeName
          ) ?? 0
      )
    );
    return {
      id: stage.id,
      label: label ?? stage.label,
      value,
      Icon: stage.Icon,
    };
  });
};

interface Stage {
  id: string;
  codenames: Array<string>;
  label: string;
  Icon?: (props: { width: number }) => JSX.Element;
}

const getLifeCycleStages = (
  intl: IntlShape,
  isFoodManufacturerOrganization: boolean
): Array<Stage> => {
  const farmStage = {
    id: "farm",
    codenames: [
      "ghg_farm",
      "ghg_feed",
      "ghg_luc_burn",
      "ghg_luc_c_stock",
      "land_seed",
      "land_arable",
      "land_fallow",
      "land_permanent_pasture",
      "land_temporary_pasture",
      "water_feed",
      "water_farm",
    ],
    label: intl.formatMessage({
      id: "components/graphs/graphDataAndStyles:lifeCycles/farm/label",
      defaultMessage: "Farm",
    }),
    Icon: Farm,
  };

  const foodServiceStages: Array<Stage> = [
    {
      id: "retail",
      codenames: ["ghg_retail"],
      label: intl.formatMessage({
        id: "components/graphs/graphDataAndStyles:lifeCycles/retail/label",
        defaultMessage: "Retail",
      }),
      Icon: Retail,
    },
    {
      id: "end-mile",
      codenames: ["ghg_end_mile"],
      label: intl.formatMessage({
        id: "components/graphs/graphDataAndStyles:lifeCycles/endMile/label",
        defaultMessage: "End-mile",
      }),
      Icon: EndMile,
    },
    {
      id: "cooking",
      codenames: ["ghg_cooking", "water_cooking"],
      label: intl.formatMessage({
        id: "components/graphs/graphDataAndStyles:lifeCycles/cooking/label",
        defaultMessage: "Cooking",
      }),
      Icon: Cooking,
    },
  ];

  return [
    farmStage,
    {
      id: "processing",
      codenames: isFoodManufacturerOrganization
        ? ["ghg_processing", "ghg_cooking", "water_processing"]
        : ["ghg_processing", "water_processing"],
      label: intl.formatMessage({
        id: "components/graphs/graphDataAndStyles:lifeCycles/processing/label",
        defaultMessage: "Processing",
      }),
      Icon: Processing,
    },
    {
      id: "packaging",
      codenames: ["ghg_packaging"],
      label: intl.formatMessage({
        id: "components/graphs/graphDataAndStyles:lifeCycles/packaging/label",
        defaultMessage: "Packaging",
      }),
      Icon: Packaging,
    },
    {
      id: "transport",
      codenames: ["ghg_tran_str"],
      label: intl.formatMessage({
        id: "components/graphs/graphDataAndStyles:lifeCycles/transport/label",
        defaultMessage: "Transport",
      }),
      Icon: Transport,
    },
    ...(isFoodManufacturerOrganization ? [] : foodServiceStages),
    {
      id: "waste",
      codenames: ["ghg_end_of_life", "ghg_loss", "land_loss", "water_loss"],
      label: intl.formatMessage({
        id: "components/graphs/graphDataAndStyles:lifeCycles/foodWaste/label",
        defaultMessage: "Food Waste",
      }),
      Icon: Waste,
    },
  ];
};

// Mint pastel is too light against a white background so it is not fit for use in the life cycle sunburst chart.
const lifeCycleDataColors = qualitativeDataColors.filter(
  (color) => color !== mintPastel
);

LifeCycleImpacts.fragments = {
  recipeImpact: gql`
    fragment LifeCycleImpacts_RecipeImpact on RecipeImpact {
      effects {
        ...LifeCycleImpacts_Effects
      }
      ingredients {
        name
        effects {
          ...LifeCycleImpacts_Effects
        }
      }
      weightKgPerServing
      unavailableLandUse
      unavailableWaterUse
    }

    fragment LifeCycleImpacts_Effects on Effects {
      stages {
        codename
        impactPerKg
        impactPerRecipe
        impactPerRootRecipeServing
        impactUnit
      }
      ghgPerKg
      ghgPerRootRecipeServing
      landUsePerKg
      landUsePerRootRecipeServing
      waterUsePerKg
      waterUsePerRootRecipeServing
    }
  `,
};
